import React from 'react';

import FaqsData from '@data/faqs-pilates.json';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import PageHeader from '@components/PageHeader/PageHeader';
import Faqs from '@components/Faqs/Faqs';

const FaqsPilates = ({ location }) => {
  const title = 'FAQs Pilates';
  const description = 'Tutto ciò che vuoi sapere sul pilates';
  return (
    <Layout>
      <Seo title={title} description={description} url={location.pathname} />
      <div className="page_container">
        <PageHeader title={title} />
        <Faqs faqs={FaqsData.data} />
      </div>
    </Layout>
  );
};

export default FaqsPilates;
