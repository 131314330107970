import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './Faqs.styles';
import Utils from '@utils/';

const useStyles = createUseStyles(Styles);

const Faqs = ({ faqs, ...props }) => {
  const classes = useStyles(props);
  const [accordion, handleAccordion] = useState(faqs.map((el, i) => i === 0));

  const handleClick = level => {
    const newAcc = JSON.parse(JSON.stringify(accordion));
    newAcc[level] = !accordion[level];
    handleAccordion(newAcc);
  };

  const renderFaqs = () => {
    return faqs.map((el, i) => {
      return (
        <div
          key={i}
          className={classes.accordion}
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <a
            href={`#accordion${i}`}
            className={classes.accordionHeader}
            onClick={e => {
              e.preventDefault();
              handleClick(i);
            }}
          >
            <h3 itemProp="name" className={classes.accordionText}>
              {el.title}
            </h3>
            <strong className={classes.accordionIcon}>
              {accordion[i] ? '-' : '+'}
            </strong>
          </a>
          <div
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
            className={`${classes.accordionContent} ${
              accordion[i]
                ? classes.accordionContentOpen
                : classes.accordionContentClosed
            }`}
          >
            <div
              itemProp="text"
              dangerouslySetInnerHTML={Utils.createMarkup(el.text)}
            ></div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>{renderFaqs()}</div>
    </div>
  );
};

Faqs.propTypes = {
  faqs: PropTypes.array,
};

export default Faqs;
