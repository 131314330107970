import Colors from '@utils/colors.js';
import Dimensions from '@utils/dimensions';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  container: {
    background: Colors.alternateBg,
    padding: '60px 20px 30px',
    margin: '0 -20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '30px 20px 30px',
    },
  },
  content: {
    maxWidth: Dimensions.contentMidWidth,
    margin: '0 auto',
    lineHeight: 1.5,
  },
  accordion: {
    marginBottom: '30px',
    maxWidth: '1000px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      marginBottom: '15px',
    },
  },
  accordionHeader: {
    fontSize: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Colors.base + '!important',
    textDecoration: 'none !important',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '20px',
    },
  },
  accordionText: {
    paddingRight: '10px',
    fontWeight: 400,
    margin: 0,
  },
  accordionContent: {
    padding: '20px 0',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '14px',
    },
  },
  accordionContentOpen: {
    display: 'block',
  },
  accordionContentClosed: {
    display: 'none',
  },
};

export default Styles;
